<template>
    <div class="file">
        <div class="labels">
            <label :for="name">
                {{ label }}
                <span v-if="required" class="asterix">*</span>
            </label>
            <p class="info">
                {{
                    !quote
                        ? $t("portfolio.add.file-info")
                        : $t("quote.step-3.question-3-info")
                }}
            </p>
        </div>
        <div class="dropbox">
            <input
                multiple
                :id="name"
                :name="name"
                :disabled="isUploading"
                type="file"
                :accept="!quote ? 'image/*' : ''"
                class="file-input"
                @change="
                    filesChange($event.target.files);
                    fileCount = $event.target.files.length;
                "
                @dragenter="fileDrag()"
                @dragleave="fileDragOut()"
            />
            <p
                v-if="isInitial"
                class="file-placeholder"
                v-html="$t('portfolio.add.file-placeholder')"
            ></p>
            <p v-if="isUploading">
                <span class="red">{{ fileCount }}</span>
                {{ $t("portfolio.add.file-uploading") }} ...
            </p>
            <p v-if="isSuccess">
                <span class="red">{{ images.length }}</span>
                {{ $t("portfolio.add.file-selected") }}
            </p>
        </div>
        <p v-if="remark" class="remark">{{ remark.message }}</p>
    </div>
</template>

<script>
const STATUS_INITIAL = 0,
    STATUS_UPLOADING = 1,
    STATUS_SUCCESS = 2,
    STATUS_FAILED = 3;

export default {
    props: {
        label: { default: "label" },
        name: { default: "name" },
        type: { default: "text" },
        placeholder: { default: "Placeholder" },
        remark: { default: null },
        required: { type: Boolean },
        edit: { type: Boolean },
        quote: { type: Boolean }
    },
    data() {
        return {
            images: null,
            uploadError: null,
            currentStatus: null,
            fileCount: null,
            isDraggedOver: false
        };
    },
    mounted() {
        this.reset();
    },
    computed: {
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isUploading() {
            return this.currentStatus === STATUS_UPLOADING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        }
    },
    methods: {
        fileDrag() {
            // Files dragged over
            document.querySelector(".dropbox").style.backgroundColor =
                "#dff5ff";
            document.querySelector(".file-placeholder").innerHTML = this.$t(
                "portfolio.add.file-drop"
            );
        },
        fileDragOut() {
            // Files dragged out
            document.querySelector(".dropbox").style.backgroundColor =
                "#f4f2f2";
            document.querySelector(".file-placeholder").innerHTML = this.$t(
                "portfolio.add.file-placeholder"
            );
        },
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.images = [];
            this.uploadError = null;
        },
        filesChange(files) {
            this.$emit("changedInput");
            // this.images = new FormData();
            const allowedExtensions = ["jpg", "png", "jpeg", "gif"],
                sizeLimit = 5000000; // 5Mb

            // Check if files
            if (!files.length) return;
            this.currentStatus = STATUS_UPLOADING;

            if (this.quote) {
                this.images = [];
            }

            // Check file sizes & types
            files.forEach(file => {
                const fileExt = file.name.split(".").pop();
                if (
                    !this.quote &&
                    !allowedExtensions.includes(fileExt.toLowerCase())
                ) {
                    this.$emit("errorType");
                    this.currentStatus = STATUS_FAILED;
                    return;
                } else if (!this.quote && file.size > sizeLimit) {
                    this.$emit("errorSize");
                    this.currentStatus = STATUS_FAILED;
                    return;
                }
                // Add to data
                this.images.push(file);
                // Add to DOM
                if (!this.quote) {
                    this.addImageToGrid(file);
                }
            });

            this.$emit("filesChanged", this.images);
            this.currentStatus = STATUS_SUCCESS;
        },
        removeImage(file) {
            if (confirm(this.$t("portfolio.add.file-remove"))) {
                // Remove from data
                this.images = this.images.filter(img => img !== file);
                // Remove from dom
                document.getElementById(file.name).remove();
                this.$emit("filesChanged", this.images);
            }
        },
        addImageToGrid(image) {
            const fReader = new FileReader();

            // Create dom elements
            const container = document.createElement("div");
            container.classList.add("thumbnail-container");
            container.id = image.name;

            const imgContainer = document.createElement("div");
            imgContainer.classList.add("inner-thumbnail-container");

            const img = document.createElement("img");
            img.classList.add("thumbnail");
            const remove = document.createElement("div");
            remove.classList.add("thumbnail-remove");
            remove.classList.add("icon");
            remove.classList.add("cancel");
            container.appendChild(remove);

            imgContainer.appendChild(img);
            container.appendChild(imgContainer);

            // Add element to grid
            fReader.onload = () => {
                img.src = fReader.result;
                this.getBase64Image(img);
                document
                    .getElementById("thumbnail-grid")
                    .appendChild(container);
            };
            fReader.readAsDataURL(image);

            // Set events
            remove.addEventListener("click", event => this.removeImage(image));
            img.addEventListener("click", event => {
                if (this.edit) {
                    this.$emit("addedThumbSelected", image);
                } else {
                    this.$emit("thumbSelected", image);
                }
            });
        },
        getBase64Image(img) {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            const dataURL = canvas.toDataURL("image/png");
            return dataURL;
        }
    }
};
</script>

<style lang="scss" scoped>
.file {
    * {
        font-size: 18px;
        letter-spacing: 0.1em;
    }

    .labels {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .info {
            color: $grey;
            font-size: 14px;
        }

        label {
            font-family: "Titillium Web", sans-serif;
            margin-bottom: 1rem;
        }
    }

    .remark {
        margin: 0;
        color: $red;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .dropbox {
        background-color: $washed-red;
        border-bottom: 3px solid $black;
        transition: background-color 0.1s ease-out;

        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 10rem;
        cursor: pointer;
        position: relative;

        & .file-input:disabled {
            background-color: red;
        }

        .file-placeholder {
            text-align: center;
            color: $grey;
            font-size: 18px;
        }

        .file-input {
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            cursor: pointer;
        }

        &:hover {
            background: $baby-blue;
        }
    }

    #grid {
        background-color: red;
        overflow: hidden;

        img {
            max-width: 10%;
        }
    }
}
</style>
